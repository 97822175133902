.App {
  text-align: center;
}

/* .ant-collapse-header-text {
  font-size: 16px;
  font-weight: bold;
} */

.hero-background {
  background-image: url('./assets/images/boskestudio1.webp');
  background-size: cover;
  background-position: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }  
}

@font-face {
  font-family: "caprice";
  src: local("caprice.medium"), url("./assets/fonts/caprice.medium.ttf") format("truetype");
}
